
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as Echarts from 'echarts'
import NoData from '@/components/NoData.vue'
@Component({
  components: { NoData }
})
export default class extends Vue {
  private loading = false
  // 列表
  private planlist: any[] = []
  // 编辑
  private planId = ''
  size = 12
  page = 1
  total = 0
  time = []
  private chart: any = {
  }

  get projectList () {
    return this.$store.state.projectList
  }

  private searchInfo = {
    projectId: '',
    day: ''
  }

  @Watch('searchInfo.projectId')
  projectId () {
    this.$axios.get(this.$apis.operationmanage.selectYhDailyPlanByList, {
      projectId: this.searchInfo.projectId
    }).then(res => {
      this.time = res.list.map((item: any) => item.planStart + '至' + item.planEnd)
    })
  }

  created () {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.operationmanage.selectYhDailyPlanByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then(res => {
      this.planlist = res.list || []
      this.total = res.total || 0
      this.planlist.forEach((plan: any, index: any) => {
        const count: number = plan.taskList.filter((t: any) => t.taskState === '1').length
        this.renderEchart(count, 'echart' + index, plan.taskList.length)
      })
    }).finally(() => {
      this.loading = false
    })
  }

  renderEchart (value: any, ref: any, total: any) {
    this.$nextTick(() => {
      this.chart['_' + ref] = Echarts.init(document.getElementById(ref) as any)
      const option = {
        series: [{
          name: '11',
          type: 'pie',
          clockWise: false,
          radius: ['75%', '100%'],
          itemStyle: {
            normal: {
              color: '#9013FE',
              shadowBlur: 0,
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          },
          hoverAnimation: false,
          data: [
            {
              value: value,
              label: {
                normal: {
                  formatter: function (params: any) {
                    return (+params.value / total * 100).toFixed(1) + '%'
                  },
                  position: 'center',
                  show: true,
                  textStyle: {
                    fontSize: 14,
                    color: '#333333'
                  }
                }
              }
            },
            {
              value: total - value === 0 ? 100 : total - value,
              name: 'invisible',
              itemStyle: {
                normal: {
                  color: '#E0E0E0'
                }
              }
            }
          ]
        }]
      }
      this.chart['_' + ref].clear()
      this.chart['_' + ref].setOption(option)
    })
  }

  onAdd () {
    this.$router.push({ name: 'PlanReportAdd' })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  onClickItem (item: any) {
    this.$router.push({ name: 'PlanReportDetail', params: { planId: item } })
  }
}
